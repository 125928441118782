/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-trailing-spaces */
import CloseIcon from '@mui/icons-material/Close';
import {
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
} from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';

import { SecondaryButton, PrimaryButton, TertiaryButton } from '../buttons';
import useModalStyles from './Modal.styles';

export interface ModalActionProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    title: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    maxWidth?: DialogProps['maxWidth'];
    isFullScreen?: boolean;
    isDisabled?: boolean;
    confirmButtonClassName?: string;
    fullWidth?: boolean;
    modalContentClassName?: string;
    additionalButtonLabel?: string;
    additionalTextButtonLabel?: string;
    handleAdditional?: () => void;
    isAdditionalDisabled?: boolean;
    displayInParent?: boolean;
    buttonsClassName?: string;
}

const ModalAction: FC<React.PropsWithChildren<ModalActionProps>> = ({
    children,
    isOpen,
    onClose,
    onSubmit,
    title,
    confirmButtonLabel,
    cancelButtonLabel,
    maxWidth,
    isFullScreen,
    isDisabled,
    confirmButtonClassName,
    fullWidth,
    modalContentClassName,
    additionalButtonLabel,
    additionalTextButtonLabel,
    handleAdditional,
    isAdditionalDisabled,
    displayInParent,
    buttonsClassName,
}) => {
    const classes = useModalStyles();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullScreen={isFullScreen}
            maxWidth={maxWidth ?? 'md'}
            fullWidth={fullWidth}
            className={clsx(modalContentClassName, {
                [classes.absolutePosition]: displayInParent,
            })}
            BackdropProps={{
                classes: { root: displayInParent ? classes.absolutePosition : '' },
            }}
            disablePortal={displayInParent}
        >
            <IconButton className={classes.closeButton} aria-label="close" onClick={onClose} size="large">
                <CloseIcon />
            </IconButton>
            <DialogTitle className={classes.title}>
                {title}
            </DialogTitle>
            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.overlayGradient}></div>
                <Container
                    className={clsx(
                        classes.buttonContainer, 
                        { [classes.buttonContainerWithText]: additionalTextButtonLabel },
                        buttonsClassName,
                    ) }
                >
                    <div className={classes.buttonWrapper}>
                        {confirmButtonLabel && (
                            <PrimaryButton
                                onClick={onSubmit}
                                className={clsx(
                                    classes.button,
                                    classes.confirmButton,
                                    confirmButtonClassName,
                                )}
                                disabled={isDisabled}
                            >
                                {confirmButtonLabel}
                            </PrimaryButton>
                        )}
                        {cancelButtonLabel && (
                            <SecondaryButton
                                className={classes.button}
                                onClick={onClose}
                            >
                                {cancelButtonLabel}
                            </SecondaryButton>
                        )}
                    </div>
                    {additionalButtonLabel && (
                        <SecondaryButton
                            className={clsx(classes.additionalButton, classes.button)}
                            onClick={() => handleAdditional?.()}
                            variation="danger"
                            disabled={isAdditionalDisabled}
                        >
                            {additionalButtonLabel}
                        </SecondaryButton>
                    )}
                    {additionalTextButtonLabel && (
                        <TertiaryButton
                            className={clsx(classes.additionalButton, classes.textButton)}
                            onClick={() => handleAdditional?.()}
                        >
                            {additionalTextButtonLabel}
                        </TertiaryButton>
                    )}
                </Container>
            </DialogActions>
        </Dialog>
    );
};

export default ModalAction;
