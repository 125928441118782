/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent, useState } from 'react';

import { PrimaryButton } from '../buttons';
import { COLORS } from '../constants/colors';
import Modal from '../Modal';

const useStyles = makeStyles({
    container: {
        margin: '0 auto',
        width: '600px',
    },
    containerMobile: {
        padding: 0,
        width: '100%',
        margin: '0 auto',
    },
    heading: {
        marginBottom: '2rem',
        color: COLORS.BLUE_DARK,
        fontSize: '1.5rem',
    },
    buttonsContainer: {
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'row',
    },
    buttonConfirm: {
        width: '150px',
    },
});

export interface ModalDialogProps {
    open: boolean;
    title: string;
    message?: string;
    buttonLabel?: string;
    onClose?: () => void;
}

const ModalDialog: FunctionComponent<ModalDialogProps> = ({
    open,
    title,
    message,
    buttonLabel,
    onClose,
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(open);
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles();

    const handleClose = (): void => {
        setIsOpen(false);
        onClose?.();
    };

    return (
        <Modal open={isOpen} onClose={handleClose} maxWidth="md">
            <div className={isMobile ? classes.containerMobile : classes.container}>
                <Typography variant="h2" component="h2" className={classes.heading}>
                    {title}
                </Typography>

                {message && (
                    <Typography sx={{ marginBottom: '2rem !important' }}>
                        {message}
                    </Typography>
                )}
            </div>

            <div className={classes.buttonsContainer}>
                <PrimaryButton
                    onClick={handleClose}
                    submitsForm
                    className={classes.buttonConfirm}
                >
                    {buttonLabel ?? 'Close'}
                </PrimaryButton>
            </div>
        </Modal>
    );
};

export default ModalDialog;
