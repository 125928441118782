import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../constants/colors';

const useModalStyles = makeStyles(styleTheme => createStyles({
    title: {
        padding: '0 2rem',
        margin: '2rem 0',
        fontWeight: 700,
        color: COLORS.BLUE,
    },
    content: {
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        padding: '2rem',
        position: 'relative',
    },
    buttonContainer: {
        display: 'flex',
        padding: 0,
        margin: '0 !important',
        justifyContent: 'flex-start',
        width: '100%',
        [styleTheme.breakpoints.only('xs')]: {
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginTop: 'auto',
        },
    },
    buttonContainerWithText: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonWrapper: {
        [styleTheme.breakpoints.only('xs')]: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 'auto',
        },
    },
    button: {
        width: '150px !important',
        height: '44px',
        fontSize: '16px',
    },
    confirmButton: { marginRight: '1rem' },
    closeButton: {
        position: 'absolute',
        right: 16,
        top: 28,
        color: `${COLORS.BLACK_12} !important`,
        '& .MuiSvgIcon-root': {
            color: `${COLORS.BLACK_12} !important`,
        },
    },
    overlayGradient: {
        position: 'absolute',
        top: '-2rem',
        left: '2rem',
        width: 'calc(100% - 4rem)',
        height: '40px',
        backgroundImage: `linear-gradient(180deg, ${COLORS.WHITE_10}, ${COLORS.WHITE})`,
    },
    additionalButton: {
        marginLeft: '1rem',
        [styleTheme.breakpoints.only('xs')]: {
            marginTop: '1rem',
            width: 'calc(50% - .5rem) !important',
        },
    },
    absolutePosition: {
        position: 'absolute',
    },
    textButton: {
        color: COLORS.BLUE,
    },
}));

export default useModalStyles;
