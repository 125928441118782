/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { SvgIconComponent } from '@mui/icons-material';
import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

import { COLORS } from '../constants/colors';

export interface IconedDataProps {
    icon?: SvgIconComponent;
    data?: string | number | null | ReactNode;
    tooltip?: string;
    iconColor?: string;
    dataColor?: string;
    bottomMargin?: string;
    leftMargin?: string;
    rightMargin?: string;
    topMargin?: string;
    iconRightMargin?: string;
    iconSize?: string;
    fontSize?: string;
    fontTextSize?: string;
    onClick?: () => void;
    isLinked?: boolean;
    dataTextBold?: boolean;
    dataLabel?: string;
    dataLabelColor?: string;
    dataLabelBold?: boolean;
    isColumn?: boolean;
    iconMarginTop?: string;
    minWidth?: string;
    capitalizeData?: boolean;
}

const IconedData = ({
    icon,
    data = '',
    tooltip,
    iconColor,
    dataColor,
    bottomMargin,
    leftMargin,
    rightMargin,
    topMargin,
    iconRightMargin,
    iconSize,
    fontSize,
    fontTextSize,
    onClick,
    isLinked,
    dataTextBold,
    dataLabel,
    dataLabelColor,
    dataLabelBold = true,
    isColumn,
    iconMarginTop,
    minWidth,
    capitalizeData,
}: IconedDataProps): JSX.Element => (
    <Box
        marginBottom={bottomMargin ?? '0.25rem'}
        marginRight={rightMargin ?? '0'}
        marginLeft={leftMargin ?? '0'}
        marginTop={topMargin ?? '0'}
        onClick={onClick}
        sx={{
            display: 'flex',
            alignItems: 'flex-start',
            minWidth: minWidth ?? 'auto',
            ...(onClick && !isLinked) && {
                ':hover': {
                    '*': {
                        color: COLORS.ORANGE,
                        cursor: 'pointer',
                    },
                },
            },
        }}
    >
        {icon && (
            <Tooltip title={<>{tooltip}</>} hidden={!tooltip} placement="top" arrow>
                <SvgIcon
                    component={icon}
                    style={{
                        color: iconColor ?? COLORS.SLATE_GREY,
                        marginRight: iconRightMargin ?? '0.625rem',
                        fontSize: iconSize,
                        marginTop: iconMarginTop ?? '0',
                    }}
                />
            </Tooltip>
        )}
        <Typography
            component="span"
            display={isColumn ? 'flex' : 'initial'}
            flexDirection={isColumn ? 'column' : 'initial'}
            alignSelf="center"
            fontSize={fontSize ?? '1rem'}
        >
            <Typography
                component="span"
                color={dataLabelColor ?? COLORS.BLACK}
                fontWeight={dataLabelBold ? 700 : 400}
                lineHeight="1.125rem !important"
                paddingRight="0.25rem"
                fontSize={fontSize ?? '1rem'}
                marginTop={isColumn ? '0.1rem' : ''}
            >
                {dataLabel}
            </Typography>
            <Typography
                marginTop={isColumn && dataLabel ? '0.5rem' : ''}
                component="span"
                color={isLinked ? COLORS.BLUE : dataColor ?? COLORS.BLACK}
                lineHeight="1.125rem !important"
                fontWeight={dataTextBold ? 700 : 400}
                fontSize={fontTextSize ?? fontSize ?? '1rem'}
                sx={{
                    ...isLinked && {
                        textDecoration: 'underline',
                        color: COLORS.BLUE,
                        ':hover': { cursor: 'pointer' },
                    },
                }}
                textTransform={capitalizeData ? 'capitalize' : 'none'}
            >
                {data ?? '-'}
            </Typography>
        </Typography>
    </Box>
);

export default IconedData;
