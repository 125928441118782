import { SvgIconComponent } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import { COLORS } from '../constants/colors';

export interface DropdownMenuOption {
    id: number;
    label: string;
    onClick?: () => void;
    icon?: SvgIconComponent;
    labelColor?: string;
    iconColor?: string;
    iconSize?: string;
}

export interface DropdownMenuProps {
    options: DropdownMenuOption[];
}

const DropdownMenu = ({ options }: DropdownMenuProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option: DropdownMenuOption): void => {
        option.onClick?.();
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon
                    sx={{ color: COLORS.BLUE_DARK,
                        fontSize: '24px' }}
                />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{ 'aria-labelledby': 'long-button' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        transform: 'translateX(-50%)',
                        width: 'auto',
                    },
                }}
            >
                {options.map(option => (
                    <MenuItem
                        key={option.id}
                        onClick={() => handleOptionClick(option)}
                        sx={{
                            padding: '.75rem 1rem',
                            '&:hover': { backgroundColor: COLORS.LIGHT_GRAY },
                        }}
                    >
                        {option.icon ? (
                            <ListItemIcon>
                                <SvgIcon
                                    component={option.icon}
                                    style={{
                                        color: option.iconColor ?? COLORS.BLACK,
                                        marginRight: '0.625rem',
                                        fontSize: option.iconSize,
                                    }}
                                />
                            </ListItemIcon>
                        ) : null }
                        <ListItemText sx={{ color: `${option.labelColor ?? ''} !important` || COLORS.BLACK }}>
                            {option.label}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default DropdownMenu;
