/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { COLORS } from '../constants/colors';
import { emptyAirline } from '../images';

const useStyles = makeStyles({
    noResultsImage: {
        maxWidth: '470px',
        maxHeight: '210px',
        margin: '0 auto',
        display: 'block',
    },
    noResultsImageMobile: {
        maxWidth: '300px',
        maxHeight: '160px',
        margin: '0 auto',
        display: 'block',
    },
    text: {
        marginTop: '2rem',
        color: COLORS.SLATE_GREY,
        fontWeight: 700,
    },
});

export interface NoResultsProps {
    iconUrl?: string;
    iconClassName?: string;
    text?: string;
    description?: string;
    additionalContent?: JSX.Element;
    icon?: JSX.Element;
    textClassName?: string;
}

const NoResultsDataGrid = ({ iconUrl, iconClassName, text, description, additionalContent, icon, textClassName }: NoResultsProps): JSX.Element => {
    const classes = useStyles();
    const mobileView = useMediaQuery('(max-width:600px)');

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            margin="2rem 0"
        >
            {icon
                ? icon : (
                    <Box>
                        <img
                            src={iconUrl ?? emptyAirline}
                            alt="No results found"
                            className={
                                iconClassName
                        ?? (mobileView ? classes.noResultsImageMobile : classes.noResultsImage)
                            }
                        />
                    </Box>
                )}
            <Typography className={textClassName ?? classes.text}>
                {text ?? 'No results found'}.
            </Typography>

            {description && (
                <Typography marginTop="0.5rem" color={COLORS.SLATE_GREY} textAlign="center">
                    {description}
                </Typography>
            )}
            {additionalContent}
        </Box>

    );
};

export default NoResultsDataGrid;
